@padding-vertical:8%;

.hidden-mobile {}
.hidden-tablet {}
.hidden-desktop {display:none;}

.visible-mobile {display:none;}
.visible-tablet {display:none;}
.visible-desktop {}


header .toggle {float:right;}
header .toggle a {display:block; width:30px; height:30px; position:relative;}
header .toggle a span, header .toggle a span::before, header .toggle a span::after {width:100%; position:absolute; top:50%; height:4px; background:@grey;}
header .toggle a span {margin-top:-2px;}
header .toggle a span::before {content:""; margin-top:-9px;}
header .toggle a span::after {content:""; margin-top:5px;}
header .toggle a.active span {background:transparent;}
header .toggle a.active span::before {transform: rotate(45deg); -webkit-transform: rotate(45deg); margin-top:0;}
header .toggle a.active span::after {transform: rotate(-45deg); -webkit-transform: rotate(-45deg); margin-top:0;}

footer {
    ul.menu.lang-inline {
        display:none;
    }
}

/* JOOMLA */
.img_caption {max-width:100% !important;}

/* layout breakpoint */
@media only screen and (max-width: 1024px) {
    html.bg {
        background:@white;
    }

    .position {
        margin:0;
        width:auto;
        background: #FFF;
        border:none;
        box-shadow:none;
    }

    header {
        background-size:cover;

        #slideshow {
            img {
                width:100%;
            }
        }
    }

    #content.sidebar {
        .item-page {
            width:auto;
        }
        aside {
            position:relative;
            top:auto;
            right:auto;
            width:auto;
            margin-top:4em;
            margin-bottom:1em;

            ul {
                margin-left:1.5em;
                list-style:disc outside;
            }
        }
    }
}

/* up to tablet */
@media only screen and (max-width: @breakpoint-tablet) {
    .hidden-mobile {display:initial;}
    .hidden-tablet {display:none;}
    .hidden-desktop {display:initial;}
    
    .visible-mobile {display:none;}
    .visible-tablet {display:initial;}
    .visible-desktop {display:none;}

    body {
        background:@grey;
    }

    input,
    select,
    textarea {
        width:80%;
    }

    .position {
        margin:0;
        width:auto;
        min-width:320px;
        background: #FFF;
        border:none;
        box-shadow:none;
    }

    header {
        height:auto;
        background:none !important;
        padding:1.2em @padding-vertical;
        overflow:hidden;
        border-bottom:1px solid @lightgrey;

        .logo {top:0; left:0;}
    }

    .main {
        margin-top:0;
        margin-bottom:2em;
    }

    nav {
        display:none; /* visible through toggle button */
        
        position:relative;
        margin:0;
        width:100%;
        box-shadow:none;

        ul.menu {
            padding-top:0;

            li {
                a {padding:.5em @padding-vertical !important;}
            }
        }

        .search {
            form {
                margin-left:@padding-vertical;
                margin-right:@padding-vertical;
            }
        }
    }

    #content {
        margin-left:auto;
        margin-right:auto;
        padding-top:0;
        padding-bottom:0;
        padding-left:@padding-vertical;
        padding-right:@padding-vertical;
        max-width:605px;
        min-height:0;
        box-shadow:none;

        img[style*="float"], img[align] {
            max-width:40%;
            margin-top:0.5em;
        }

        img[style*="float:right"], img[align="right"] {
            margin-left:1em;
        }

        img[style*="float:left"], img[align="left"] {
            margin-right:1em;
        }
    }

    footer {
        margin:0;
        padding-left:@padding-vertical;
        padding-right:@padding-vertical;
        color:fadeout(@white, 40%);
        text-align:center;
        background:@grey;

        a {
            color:@white !important;
        }

        ul.menu {
            float:none;
            margin-bottom:1.5em;
            line-height:2em;

            li {
                border:none;

                a {
                    margin:0.5em 1.25em !important;
                }
            }
        }

        ul.menu.lang-inline {
            display:block;

            li {
                a {
                    margin:0 0.5em !important;
                }
            }
        }
    }

    /* Homepage */
    html.home div.columns {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;

        -webkit-column-gap: 2em;
        -moz-column-gap: 2em;
        column-gap: 2em;
    }
    html.home div.table {display:table; width:100%;}
    html.home div.table:nth-child(2n) {border-top:0;}
    html.home div.table > div {display:table-cell; vertical-align:middle; padding:0.5em;}
    html.home div.table > div:first-child {width:65px}
    html.home div.table img {display:block; width:65px; max-width:none;}
}

/* up to mobile */
@media only screen and (max-width: @breakpoint-mobile) {
    .hidden-mobile {display:none;}
    .hidden-tablet {display:initial;}
    .hidden-desktop {display:initial;}
    
    .visible-mobile {display:initial;}
    .visible-tablet {display:none;}
    .visible-desktop {display:none;}

    header {
        padding:1.2em (@padding-vertical/1.5);

        .logo img {
            margin-top:2px;
            width:165px;
            height:22px;
        }
    }

    .main {
        hyphens:auto;
        -ms-hyphens:auto;
        -webkit-hyphens:auto;
    }

    nav {

        ul.menu {
            li {
                a {padding:.5em (@padding-vertical/1.5) !important;}
            }
        }

        .search {
            form {
                margin-left:(@padding-vertical/1.5);
                margin-right:(@padding-vertical/1.5);
            }
        }
    }

    #content {
        padding-left:(@padding-vertical/1.5);
        padding-right:(@padding-vertical/1.5);
    }

    footer {
        background:@brand-color;
        padding-left:(@padding-vertical/1.5);
        padding-right:(@padding-vertical/1.5);

        ul.menu {
            margin-left:-(@padding-vertical/1.5);
            margin-right:-(@padding-vertical/1.5);

            li {
                display:block;
                border-bottom:1px solid fadeout(@white, 50%);

                a {
                    display:block;
                    margin:0.25em !important;
                }
            }
        }

        ul.menu.lang-inline {

            li {
                display:inline-block;
                border:none;

                a {
                    margin:0 0.5em !important;
                }
            }
        }
    }

    /* Homepage */
    html.home div.columns {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
    }
    html.home div.table:nth-child(n+2) {border-top:0;}

    /* Kontakte */
    .contact {
        .contact-contactinfo {
            margin-bottom:2em;
        }

        .contact-image {
            max-width:40%;
            margin-left:1em;
        }

        span[class$="-text"] {
            min-width:0;
            margin-right:0;
        }
    }

    /* Veröffentlichungen */
    .blog {
        .img-intro-left {
            max-width:30%;
            margin-right:5%;
        }
        .text-intro-float-left {
            margin-left:40%;
        }
    }
}
