/* HTML *************************** */
html.bg {height:100%; background:#E7E3E4 url('../images/body-bg.jpg') fixed center top no-repeat; background-size:cover;}
body {font:13px/1.66em Arial,Helvetica,sans-serif; color:@font-color; min-height:100%; background:transparent;}

body.js {}
body.ie8 {}
body.nosvg {}

h1,h2,h3 {color:@brand-color; font-weight:bold;}
h4,h5,h6 {font-weight:bold;}
p {margin-bottom:1em;} 
a {color:@brand-color; text-decoration:none;}
a:hover {text-decoration:underline;}
ol, ul {margin:0 0 1em 1.2em; list-style:disc;}
li {}
label {font-style:italic;}
form {}
fieldset + fieldset {margin-top:2em;}
fieldset legend {font-weight:bold; display:block; margin-bottom:1em;}
input,select,textarea,button,.button {width:80%; padding:.5em; border:1px solid @lightgrey; border-radius:3px; ackground:rgba(255,255,255,0.8)}
select {width:calc(80% + 2em); padding-top:0.25em; padding-bottom:0.25em;}
input[type=submit], button, .button, .button:hover {width:auto; cursor:pointer; border:none; color:@white; background:@darkgrey; text-decoration:none;}
input[type=checkbox], input[type=radio] {height:auto; width:auto;}
input[readonly] {color:grey; cursor:not-allowed;}
input.placeholder {color:#666;}
textarea {width:50%; font-family:Arial,Helvetica,sans-serif; overflow:auto;}
img {max-width:100%; height:auto;}
body.ie7 img[align=right] {float:right;}
span {}
acronym {cursor:help;}
blockquote {}
table {margin-bottom:1em; line-height:1.4em;}
body.ie7 table {font-size:0.9em; width:100%;}
th, td {padding:.25em .5em; border:1px solid @lightgrey; vertical-align: top; white-space:nowrap;}
hr {margin:2em 0; height:1px; border:0; border-top:1px solid @lightgrey; clear:both;}
sup {position:relative; top:-0.5em; vertical-align:bottom; font-size:0.75em;}
sub {position:relative; bottom:-0.5em; vertical-align:bottom; font-size:0.75em;}
dl {margin-bottom:1em;}
dt, dd {line-height:auto;}
dt {font-weight: bold;}
dd {margin-left:1em;}
small {font-size:0.8em; line-height:1em;}

html[class*="home"] div.columns {-webkit-column-count: 4; -moz-column-count: 4; column-count: 4;}
html[class*="home"] div.table {border-top:1px solid @lightgrey; border-bottom:1px solid @lightgrey; }
html[class*="home"] div.table > div {text-align:center; padding:0 0.25em;}
html[class*="home"] div.table > div:first-child {padding-top:0.5em;}
html[class*="home"] div.table > div:last-child {padding-bottom:0.5em;}
html[class*="home"] div.table > div a {color:@font-color; text-decoration:none;}

/* LAYOUT ******************************* */
.centerContainer {position:relative;}
.position {margin:-1px auto 2em; width:940px; background:@white; border:1px solid @lightgrey; box-shadow:0 0 8px rgba(170,170,170,1.0);}
header {height:325px; overflow:hidden; background:transparent url('../images/header-bg.jpg') 0 0 no-repeat;}
  header .centerContainer {height:100%;}
  .logo {position:absolute; top:30px; left:19px; z-index:3;}
  .metamenu {position:absolute; bottom:0; left:0; width:100%; text-align:right; background:rgba(255,255,255,0.6); z-index:2;}
  #slideshow {position:absolute; top:0; left:0; width:100%; min-width:940px; height:325px; z-index:1; overflow:hidden;}
    #slideshow img {position:absolute; top:0; left:0; display:block;}
.main {margin-top:5px;}
  .main .centerContainer {overflow:hidden;}
  nav {position:absolute; top:0; left:0; margin: 0 0 30px 20px; padding-bottom:5px; width:200px; background:@brand-color; box-shadow:0 8px 3px -6px rgba(170,110,100,0.9);}
  #content {overflow:auto; margin:15px 55px 30px 140px; padding:0 30px 30px 110px; min-height:450px; background:@white; box-shadow:6px 10px 15px rgba(170,110,100,0.3);}
ul.breadcrumb {width: 100%; margin-left: 0; padding:0 0 1em; display: block; overflow: hidden;}
ul.breadcrumb li {margin: 0 .25em 0 0; list-style-type: none; display: inline-block; float: left;}
footer {clear:both; margin:0 39px; padding-top:1em; padding-bottom:1em; border-top:1px solid @lightgrey;}

#content.sidebar .item-page {width:375px;}
#content.sidebar aside {position:absolute; top:6.5em; right:75px; width:200px;}
#content.sidebar aside ul {list-style:disc outside;}

/* .item-page a:before {content:'» ';} */
a[href$='.pdf'] {color:@font-color; padding:.1em 0 .1em  1.6em; background:transparent url('../images/icons/pdf.png') 0 center no-repeat;}
a[href$='.exe'], a[href$='.zip'], a[href$='.msi'] {color:@font-color; padding:.1em 0 .1em  1.6em; background:transparent url('../images/icons/exe.png') 0 center no-repeat;}
a[href$='.doc'], a[href$='.docx'] {color:@font-color; padding:.1em 0 .1em 1.6em; background:transparent url('../images/icons/doc.png') 0 center no-repeat;}
a[href$='.ppt'], a[href$='.pptx'] {color:@font-color; padding:.1em 0 .1em  1.6em; background:transparent url('../images/icons/ppt.png') 0 center no-repeat;}
a[href$='.xls'], a[href$='.xlsx'] {color:@font-color; padding:.1em 0 .1em  1.6em; background:transparent url('../images/icons/xls.png') 0 center no-repeat;}
a[href$='.txt'], a[href$='.d81'] {color:@font-color; padding:.1em 0 .1em 1.6em; background:transparent url('../images/icons/txt.png') 0 center no-repeat;}

a[href$='.pdf'][href*='login'] {background-image:url('../images/icons/pdf-locked.png');}
a[href$='.exe'][href*='login'], a[href$='.zip'][href*='login'], a[href$='.msi'][href*='login'] {background-image:url('../images/icons/exe-locked.png');}
a[href$='.doc'][href*='login'], a[href$='.docx'][href*='login'] {background-image:url('../images/icons/doc-locked.png');}
a[href$='.ppt'][href*='login'], a[href$='.pptx'][href*='login'] {background-image:url('../images/icons/ppt-locked.png');}
a[href$='.xls'][href*='login'], a[href$='.xlsx'][href*='login'] {background-image:url('../images/icons/xls-locked.png');}
a[href$='.txt'][href*='login'], a[href$='.d81'][href*='login'] {background-image:url('../images/icons/txt-locked.png');}

table a[href$='.pdf'] {display:inline-block; width:1.3em; margin:0 auto; padding:0; text-indent:1000px; overflow:hidden;}
body.ie7 table a[href$='.pdf'] {float:left;}
table a.texticon {width:auto; margin:0; padding:.1em 0 .1em 1.6em; text-indent:0; overflow:visible;}
table td img {margin:-.25em -.5em; display:block; max-width:none;} /* product pictures */
table td:first-child img {display:inline; margin:0;} /* flags */

.hide {display:none !important;}
.noprint {}
.print {display:none;}

.label-info {background:@brand-color; padding:0.125em 0.5em; border-radius:4px; color:white;}

/* PAGES ******************************* */
html.form #content {margin:0; padding:2em 39px; box-shadow:none;}

html.offline body {background:none;}
html.offline .main {margin:3em auto; padding:3em; width:400px; background:@white; text-align:center; box-shadow:0 0 10px rgba(0,0,0,0.3);}

html.error body {background:none;}
html.error h1 {margin:1em 0; background:@brand-color; color:white; line-height:1.5em;}
html.error h3 {margin:1em 0; background:#5F6565; color:white; line-height:1.5em; text-align:center;}
html.error .main {margin:3em auto; padding:3em; width:400px; background:@white; text-align:center; box-shadow:0 0 10px rgba(0,0,0,0.3);}
html.error .main .errortext {text-align:left;}

/* IE FIXES ******************************* */
body.ie .metamenu {background:transparent url('../images/white-bg.png') 0 0 repeat;}
body.bgfix .position {position:relative; z-index:2;}
body.bgfix #bg {position:fixed; top:0; left:0; width:100%; height:100%; overflow:hidden;}
body.bgfix #bg img {width:100%;}
