@media print { 
    /* HTML *************************** */
    html {background:none;}
    a {padding:0 !important; background:none !important; text-decoration:underline;}

    /* LAYOUT ******************************* */
    .position {margin:0; width:100%; background:none; border:none; box-shadow:none;}
    header {height:auto; padding:1em 0; background:none;}
    .logo {position:relative; top:auto; left:auto;}
    .main {margin:0; padding:2em 0; border-top:1px solid;}
    #content {margin:0; padding:0; min-height:0; background:none; box-shadow:none;}
    footer {margin:0; padding:1em 0;  line-height:1em; border-top:1px solid;}
    footer p {margin-top:1em;}

    .noprint {display:none;}
    .print {display:block;}
}
