/* MODULES */
nav ul.menu {margin:0; padding:1em 0; font-size:1.1em; line-height:1.8em; list-style:none;}
nav ul.menu li {border-top:1px solid fadeout(@white, 50%);}
nav ul.menu>li:first-child {border-top:0;}
nav ul.menu li a {padding:.25em 1em; display:block; color:@white; text-decoration:none; line-height:1.5em;}
nav ul.menu li a:hover, nav ul.menu li.active a {color:@font-color;}
nav ul.menu li ul {margin:0; list-style:none;}
nav ul.menu li ul li {border:0;}
nav ul.menu li ul li a, nav ul.menu li.active ul li a {padding-left:2em; font-size:.85em; color:@darkgrey; background:fadeout(@white,10%); border-bottom:1px solid @lightgrey;}
nav ul.menu li ul li.last a {border-bottom:none;}
nav ul.menu li ul li a:hover, nav ul.menu li ul li.active a {color:@white; background:@darkgrey;}

.metamenu ul.menu {display:inline; margin:0; line-height:2em;}
.metamenu ul.menu.lang-inline {margin-right:45px;}
.metamenu ul.menu li {display:inline; margin-right:3em;}
.metamenu ul.menu.lang-inline li {margin-right:1em;}
.metamenu ul.menu li a {color:@font-color; text-decoration:none;}
.metamenu ul.menu li a.registered {color:@brand-color; font-weight:bold;}

.breadcrumbs a {color:@font-color; text-decoration:none;}
.breadcrumbs span {color:@brand-color;} /* letztes Element */

footer ul.menu {margin:0; float:right; list-style:none;}
footer ul.menu li {display:inline-block; border-left:1px solid @lightgrey;}
footer ul.menu li a {margin:0 1.5em; color:@font-color; text-decoration:none;}
footer ul.menu li.last a {margin-right:0;}

header .moduletable {position:absolute; bottom:35px; right:55px; min-width:250px; max-height:270px; overflow:auto; background:rgba(240,50,40,0.9); color:white; z-index:4; border:1px solid @brand-color;}
header .moduletable .custom {padding:1em 2em; background-position:right top;}
header .moduletable h4 {font-size:1.3em; color:white;}
header .moduletable p {margin:0;}
header .moduletable a {color:white; text-decoration:none;}
header .moduletable.no-background {background:none; border:none;}
header .moduletable.no-background .custom {padding:0;}

header .contactinfo {padding:1em 5em 1em 1em; color:@font-color; background:transparent url('../images/contactinfo-bg.png') right top no-repeat; display:none; border:none;}
header .contactinfo h4 {font-size:1em; color:@font-color;}
header .contactinfo a {color:@font-color;}
header .contactinfo .info_name {font-weight:bold;}
header .contactinfo .info_position {color:@brand-color;}
header .contactinfo .info_sortname {font-style:italic;}
header .contactinfo .contact_sep {display:block; height:1.5em;}

#content img[style*="float"] {margin-top:0.5em;}
#content .custom  * {margin:0;}
#content .marquee {width:100%; margin:0 auto; overflow: hidden; white-space: nowrap; box-sizing: border-box; animation: marquee 20s linear infinite;}
#content .marquee, #content .marquee a {color:@font-color;}
#content .marquee:hover {animation-play-state: paused}
@keyframes marquee {
    0%   { text-indent: 610px }
    100% { text-indent: -610px }
}

/* COMPONENTS */
.page-header {margin-bottom:1em;}

.blog .img-intro-left {float:left; width:150px; margin:0 2em 1em 0;}
.blog .text-intro-float-left {margin-left:180px;}
html.links .blog .img-intro-left {float:left; width:55px; margin:0 2em 1em 0;}
html.links .blog .text-intro-float-left {margin-left:5em;}
.blog .item-separator {clear:left;}
.blog .last .item-separator {display:none;}

html.offene-stellen .blog .has-text-intro .text-intro {border:1px solid; padding:1em; margin-bottom:1em;}
html.offene-stellen .blog .has-text-intro .text-intro *:first-child {padding-top:0; margin-top:0;}
html.offene-stellen .blog .has-text-intro .text-intro *:last-child {padding-bottom:0; margin-bottom:0;}

.search input {border-top-right-radius:0; border-bottom-right-radius:0;}
.search button {border-top-left-radius:0; border-bottom-left-radius:0; padding-top:0.5px; padding-bottom:0.5px;}
nav .search form {margin:0 1em 1em; background:white; background:rgba(255,255,255,0.8); border:1px solid @lightgrey; border-radius:3px;}
nav .search input {width:85%; max-width:none; padding:0 2.5%; border:none; box-sizing:border-box; background:transparent;}
nav .search button {text-indent:-1000px; overflow:hidden; width:15%; padding:0; height:1.95em; border:none; background:transparent url('../images/bg-button-search.png') right center no-repeat;}

.search-results {list-style:none; margin-left:0;}
.search-results .highlight {background:lightgrey;}
.search-results li ~ li {margin-top:2em;}
.search-results .result-title {font-weight:bold;}
.search-results span + span {margin-left:1em;}
.search-results .result-category, .search-results .result-created {color:grey;}
.search-results .result-text {display:block; margin-left:0;}

.contact {}
.contact.odd {margin-top:3em;}
.contact h2, .contact h3 {color:@font-color;}
.contact .contact-position {color:@brand-color;}
.contact .contact-image {margin:0.25em 0 1em; width:200px; float:right;}
.contact .contact-contactinfo + .contact-contactinfo {margin-top:3em;}
.contact .contact-contactinfo a {color:@font-color;}
.contact span[class$="-text"] {display:inline; display:inline-block; min-width:4.5em; margin-right:1em;}
.contact .pbcontactmap {height:200px; margin-bottom:1em;}

.control-group {margin-bottom:0.75em;}
.control-group .controls input.invalid {border:1px solid @brand-color;}
.control-group .controls fieldset.radio.invalid {color:@brand-color;}
.control-group .controls fieldset.checkboxes.invalid {color:@brand-color;}

.pagination {text-align:center;}
.pagination ul {margin-left:0; list-style:none;}
.pagination ul li {display:inline-block;}
.pagination ul li span, .pagination ul li a {padding:.25em 0.75em;}
.pagination ul li span {color:grey;}

.img_caption {text-align:center; color:grey;}

[id^="system-message"] .alert {margin-bottom:1em; padding:.5em 1em; background:lighten(@lightgrey, 20%); border-radius:5px;}
[id^="system-message"] .notice, [id^="system-message"] dt.message {font-weight:bold;}
[id^="system-message"] .message ul {margin:0; list-style:none;}
[id^="system-message"] .alert .close {float:right; cursor:pointer; padding:0.25em; line-height:1em; background:none; text-decoration:none; opacity:0.5;}
[id^="system-message"] .alert-error * {color:#ec3525;}

.login label#password-lbl, .login label#username-lbl {display:block;}

ul.actions {list-style:none;}
ul.actions li {float:right;}
ul.actions li a {text-decoration:none;}

.edit #jform_title {width:92%;}
.edit p.formelm label {display:inline; display:inline-block; width:200px;}
.edit p.formelm label#jform_title-lbl {display:inline; width:auto; margin-right:1em;}
.edit .optional {font-size:0.75em; color:grey;}

.tip-wrap {max-width:400px; padding:.5em 1em; background:@darkgrey; color:@white; box-shadow:0 0 8px rgba(170,170,170,1.0);}
.tip-title {font-weight:bold;}

iframe html, body#tinymce {background:white !important;}
body#tinymce table a[href$='.pdf'] {float:left; width:auto; margin:0; padding-left:1.6em; text-indent:0; overflow:visible;}

/* sitemap */
#osmap ul {list-style:none;}
#osmap ul li {font-weight:bold;}
#osmap ul li ul li {font-weight:normal;}
#osmap ul.level_0 > li {margin-top:1em;}
#osmap ul.level_1 {border-left:1px solid lightgrey; padding-left:1em; margin-bottom:0;}
#osmap ul.level_2 {display:none;}
#osmap ul[id*="footermenu"] a[href$="/de"] {display:none;} /* Startseite */
#osmap ul[id*="footermenu"] a[href$="/de/login"] {display:none;} /* Login */
#osmap ul[id*="footermenu"] a[href$="/en"] {display:none;} /* Home */
#osmap ul[id*="footermenu"] a[href$="/en/login"] {display:none;} /* Login */

/* bootstrap */
.dl-horizontal::before, .dl-horizontal::after {display:table; content:""; line-height:0;}
.dl-horizontal::after {clear:both;}
.dl-horizontal dt {float:left; width:25%; clear:left; padding-right:5%; overflow:hidden; text-overflow:ellipsis; white-space:nowrap;}

/* popover */
div.popover {display:none !important;}

/*acymailing */
.acysubscribe {padding-top:0.75em;}
.acysubscribe td {padding:0;}
.acysubscribe input[type="checkbox"] {margin-left:0;}

#unsubpage {
  .unsubsurvey, .unsubintro, .unsuboptions {padding: 0;}
  .unsubintro, .unsuboptions {margin-bottom: 2em;}

  .unsubintro br + br {display: none;}
  .unsubsurveytext {margin-bottom: 1em;}
  #otherreasons {
    margin-top: 1em;

    textarea {
      width: 100% !important;
      height: 3em !important;
    }
  }

  #unsubbutton_div {margin-top:2em;}
}

.acymailing_module {
  .acysubbuttons {text-align: left;}
}
