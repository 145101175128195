@charset "utf-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain)
*/
html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline;}
/* HTML5 display-role reset for older browsers */
article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block;}
body{line-height:1;}
ol,ul{list-style:none;}
blockquote,q{quotes:none;}
blockquote:before,blockquote:after,q:before,q:after{content:'';content:none;}
table{border-collapse:collapse;border-spacing:0;}
/*  Useful basic stylings based on Stefan Nitzscheâ€™s work: http://nitzsche.info */
html{overflow-y:scroll;}
body{background-color:#fff;}
strong,b{font-weight:700;}
em,i{font-style:italic;}
h1,h2,h3,h4,h5,h6{font-weight:400;}
.ie7 address{font-style:normal;}
textarea {overflow:auto;font-size:1em;line-height:1em;}
input{margin:0;padding:0;border:0;background:transparent;outline:none;font-size:1em;line-height:1em;text-shadow:none;border-radius:0;}
label,input[type=button],input[type=submit],button{cursor:pointer;}
abbr[title]{border-bottom:1px dotted #888!important;cursor:help;}
a abbr{cursor:pointer!important;}
a:focus,a:hover {outline:none;}
img {border:none;}
.clearfix{clear:both;content:".";display:block;height:0;visibility:hidden;}
.hide {display:none;}
.print {display:none;}
/* Hides from IE-mac \*/
* html .clearfix{height:1%;}
/* End hide from IE-mac */
img{-ms-interpolation-mode:bicubic;}
